<template>
	<div id="conduct">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>德育</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">操行评定</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f-item u-f-jsb" style="padding-bottom: 15px;">
					<div>共{{ total }}条数据</div>
					<el-button size="small" @click="exportsConduct">导出</el-button>
				</div>
				<el-table :data="List" height="88%" style="width: 100%;border: 1px solid #EEEEEE;">
					<el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
					<el-table-column prop="student.realname" label="学生姓名" align="center"></el-table-column>
					<el-table-column prop="semester.name" label="评定类型" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.type==1">优</span>
							<span v-if="scope.row.type==2">良</span>
							<span v-if="scope.row.type==3">合格</span>
							<span v-if="scope.row.type==4">不合格</span>
						</template>
					</el-table-column>
					<el-table-column prop="evaluate" label="评价" align="center"></el-table-column>
					<el-table-column label="图片" align="center">
						<template  slot-scope="scope">
							<div class="u-f-justify">
								<el-image v-for="(item,index) in scope.row.images" :key='index' style="width: 50px; height: 50px":src="item" fit="cover" :preview-src-list="[item]">
									<div slot="error" class="u-f-justify" style="width: 100%;height: 100%;background: #F5F7FA;">
									    <i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="createtime" label="创建时间" align="center"></el-table-column>
					<el-table-column prop="teacher.username" label="创建人" align="center"></el-table-column>
					<el-table-column align="center" label="操作" width="150">
						<template slot-scope="scope">
							<!-- <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button> -->
							<el-button @click="del(scope.row.id)" type="text" size="small" style="color: red;">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-item u-f-right" style="padding: 15px 0;">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			total:0,
			List:[],
			page:1,
			limit:10
		};
	},
	mounted() {
		this.getteacherAssessList()
	},
	methods: {
		// 导出
		exportsConduct(){
			this.$api.setting.exportsConduct().then(res=>{
				if(res.data.code==1){
					window.open(res.data.data.url)
				}
			})
		},
		handleSizeChange(e){
			
		},
		handleCurrentChange(e){
			this.page = e;
			this.getteacherAssessList()
		},
		handleClose(done){
			this.init()
			done()
		},
		// 获取考核列表
		getteacherAssessList(){
			this.$api.station.evaluationConductList({
				page:this.page,
				limit:this.limit
			}).then(res=>{
				if(res.data.code==1){
					this.total = res.data.data.total;
					this.List = res.data.data.rows;
				}
			})
		},
		// 删除考核
		del(id){
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.station
					.delEvaluationConduct({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getteacherAssessList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		}
	}
};
</script>

<style lang="scss">
	#conduct{
		.content{
			background-color: #ffffff;
			height: 80vh;
			margin-top: 20px;
			padding:15px 20px;
			.el-table {
				table-layout: fixed;
			}
			.el-table th > .cell,
			.el-table .cell {
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*不换行*/
				text-overflow: ellipsis; /*超出部分文字以...显示*/
			}
			.el-table thead {
				background: #fafafa !important;
			}
			.el-table th {
				padding: 15px 5px !important;
				background: #fafafa !important;
			}
			.el-table::before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
			}
			.el-table .btn {
				padding: 5px 10px;
				text-align: center;
				font-size: 12px;
			}
		}
		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 20px;
			.el-dialog__title {
				font-size: 16px;
			}
		}
		.el-dialog__body {
			padding: 0 20px 35px 20px;
			overflow-y: auto;
			max-height: 600px;
			.edit-box {
				.input-box {
					margin-top: 35px;
					padding-left: 15px;
					.input-lebal {
						color: #aeb5b8;
						margin-right: 10px;
						span {
							color: #ff7a65;
							margin-right: 3px;
						}
					}
					.el-icon-remove-outline {
						font-size: 1.5625vw;
						color: #d7d9db;
						margin-left: 10px;
					}
				}
				.avatar-uploader .el-upload {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
				}
				.avatar-uploader .el-upload:hover {
					border-color: #409eff;
				}
				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width: 90px;
					height: 90px;
					line-height: 90px;
					text-align: center;
				}
				.avatar {
					width: 90px;
					height: 90px;
					display: block;
				}
			}
		}
		.el-dialog__footer {
			padding: 15px 20px 15px;
			box-shadow: 0px -5px 10px -5px #eeeeee;
		}
	}
	.selectShow {
		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 20px;
			.el-dialog__title {
				font-size: 16px;
			}
		}
		.el-dialog__body {
			padding: 0;
			.user-list-box {
				.user-list-head {
					padding: 10px 20px;
					border-bottom: 1px solid #eeeeee;
					.user-type-list {
						.user-type-item {
							color: #8d9da6;
							cursor: pointer;
						}
						.active {
							color: #007aff;
							position: relative;
						}
						.active::after {
							position: absolute;
							width: 25px;
							height: 2px;
							background-color: #007aff;
							content: '';
							bottom: -5px;
							left: 0;
						}
						span {
							color: #cccccc;
							margin: 0 8px;
						}
					}
				}
				.user-list-content {
					height: 40vh;
					.left {
						height: 98%;
						overflow-y: auto;
						border-right: 1px solid #eeeeee;
						padding-top: 5px;
						.left-item {
							padding: 0 20px;
							height: 40px;
							line-height: 40px;
							color: #a4a4a4;
							cursor: pointer;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
							position: relative;
						}
						.active:after {
							position: absolute;
							top: 0;
							bottom: 0;
							right: 0;
							width: 2px;
							content: '';
							background-color: #2d8cf0;
						}
					}
					.right {
						height: 37vh;
						padding: 10px 25px;
						overflow-y: auto;
						.list {
							display: flex;
							flex-wrap: wrap;
							.item {
								cursor: pointer;
								padding: 5px 15px;
								border-radius: 3px;
								background-color: #f3f3f3;
								margin-right: 10px;
								margin-bottom: 10px;
							}
							.active {
								background-color: #e4f1ff;
								color: #2d8cf0;
							}
						}
					}
				}
			}
		}
		.dialog-title {
			padding: 10px 0 5px 0;
		}
		.el-dialog__footer {
			padding: 15px 20px 15px;
			box-shadow: 0px -5px 10px -5px #eeeeee;
		}
	}
</style>
